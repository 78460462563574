h3:hover {
    -webkit-text-stroke: 1px rgb(207, 110, 194);
    -webkit-text-fill-color: white;

}
input:hover {
    color:rgb(207, 110, 194);
    box-shadow: 2px black;
}
button:hover{
    color:rgb(224, 228, 31) !important;
}