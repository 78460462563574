* {
    font-family: 'Luxurious Roman', cursive;
}

.big-img {
    background-image: url('../assets/dahlia\ background.jpg');
    min-height: 220px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
};

.container-fluid {
    margin-left: 0 auto;
    position: inherit;
    font-family: 'Fredericka the Great', cursive;
    background-color: transparent;

}
.myName {
    /* font-family: 'Bungee Shade', cursive; */
    font-family: 'IM Fell English SC', serif;
    background-color: transparent;
    font-size:52px;
}
.myName:hover {
    color: rgb(224, 228, 31) !important;
;
}
.navbar {
    overflow: hidden;
    margin-left: 0;
    font-family: 'Fredericka the Great', cursive;
    background-color: transparent;

}
a {
    color: white !important;
    text-decoration: none !important;
    font-family: 'Fredericka the Great', cursive;
    background-color: transparent;

}
a:hover {
    -webkit-text-stroke: 1px rgb(207, 110, 194);
    -webkit-text-fill-color: white;

}

.nav-item {
    font-size:xx-large;
    font-family: 'Fredericka the Great', cursive;
    background-color: transparent;
}