* {
    background-color: rgb(37, 37, 37);
}
.aboutHeading {
    padding-left: 2%;
    padding-top: 1%;
}
.aboutHeading:hover{
    color: rgb(209, 191, 24) !important;
}
.img-fluid {
    height: 250px !important;
    border-radius: 25% !important;
    
}

.aboutText {
    
    opacity: 0;
    animation-name: fx-fadein;   
    animation-duration: 1s; 
    animation-timing-function: ease-out; 
    animation-delay: 1s;
    animation-fill-mode: both;
  }

  @keyframes fx-fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
